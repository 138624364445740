import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogTwenty from "../../components/Blog/BlogsList/BlogTwenty"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogTwenty />
    </BlogLayout>
  )
}

export default BlogContent
